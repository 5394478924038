<template>
  <div>
    <template>
      <ContactForm></ContactForm>
    </template>
  </div>
</template>
<script>
import ContactForm from "../components/ContactForm.vue";

export default {
  name: "Contact",
  components: {
    ContactForm,
  },
};
</script>

<style>
</style>