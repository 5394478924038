<template>
  <div>
    <template>
      <ProductSelection></ProductSelection>
    </template>
  </div>
</template>
<script>
import ProductSelection from "@/components/ProductSelection.vue";
export default {
  name: "Template",
  components: {
    ProductSelection,
  },
};
</script>
