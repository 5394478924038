<template>
  <div>
    <template>
      <CreateAnnouncement></CreateAnnouncement>
    </template>
  </div>
</template>
<script>
import CreateAnnouncement from "@/components/CreateAnnouncement.vue";
export default {
  name: "Create",
  components: {
    CreateAnnouncement,
  },
};
</script>
